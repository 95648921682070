<template>
  <Component v-if="order && printLayout" :is="printLayout" v-bind="bindProps"></Component>
</template>
<script>
import VueBarcode from 'vue-barcode';
import QrcodeVue from 'qrcode.vue'
import PrintOrderDefault from "./Labels/LabelDefault";
import PrintOrderImage from "./Labels/LabelImage";
import PrintOrderPDF from "./Labels/LabelPDF";
import PrintOrderEnvelop from "./Labels/LabelEnvelop";

export default {
  name: 'PrintLabel',
  components: {
    PrintOrderImage,
    PrintOrderPDF,
    /*PrintOrderTiny,*/
    PrintOrderEnvelop,
    PrintOrderDefault,
    'barcode': VueBarcode,
    QrcodeVue
  },
  props: [
    'printSubPage',
    'printTemplate',
    'order'
  ],
  data() {
    return {
      printLayout: null,
      orderCode: 'ORDER-000000',
      link: '',
      size: 200,
    }
  },
  computed: {
    bindProps() {
      return {
        order: this.order,
        printSubPage: this.printSubPage
      }
    },
    base64ImageTracking() {
      let imgBase64 = null;
      if (this.order && this.order.buy_tracking_status !==3 && this.order.last_tracking_number) {
        imgBase64 = this.order.last_tracking_number.label_data
      }
      return imgBase64;
    }
  },
  watch: {
    order: {
      handler() {
        if (this.order) {
          if (!this.base64ImageTracking) {
            let templateName = this.printTemplate[this.order.service_id];
            switch (templateName) {
              case 'Default':
                this.printLayout = PrintOrderDefault;
                break;
                /*
                case 'Tiny':
                  this.printLayout = PrintOrderTiny;
                break;
                 */
              case 'Envelop':
                this.printLayout = PrintOrderEnvelop;
                break;
              default:
                this.printLayout = PrintOrderDefault;
                break;
            }
          } else {
            if (this.order && this.order.last_tracking_number) {
              if (this.order.last_tracking_number && this.order.last_tracking_number.label_type === 'pdf') this.printLayout = PrintOrderPDF;
              else this.printLayout = PrintOrderImage;
            } else {
              this.printLayout = PrintOrderImage;
            }
          }
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>
.zip-barcode svg.vue-barcode-element {
  width: 5.5cm;
}

.order-barcode svg.vue-barcode-element {
  width: 14.75cm;
  height: 5.5cm;
  position: absolute;
  top: -3.3cm;
  left: -7.35cm;
}
</style>
