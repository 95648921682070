<template>
  <div style="text-align: center">
    <img style="padding-bottom: 10px; padding-top: 10px; display: block; margin: 0 auto; max-width: 400px; max-height: 548px;" v-for="(imgBase64, key) in base64ImageTracking"
         :key="key" :src="'data:image/png;base64,' + imgBase64" alt="">
  </div>
</template>
<script>

export default {
  name: 'LabelImage',
  props: ['order'],
  computed: {
    base64ImageTracking() {
      let imgBase64 = null;
      if (this.order && this.order.last_tracking_number) {
        imgBase64 = this.order.last_tracking_number.label_data
      }
      if (!imgBase64) return [];
      if (this.isJson(imgBase64)) {
        return JSON.parse(imgBase64)
      } else {
        return [imgBase64]
      }
    }
  },
  methods: {
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
  }
}
</script>
<style scoped lang="scss">
@media print {
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;

  }

  @page {
    size: A4;
    size: portrait;
  }
}
</style>
