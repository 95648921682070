<template>
  <div>
    <div v-if="status !== 'init'">
      <template v-for="orderId in listOrderIds">
        <PrintLabel :order="orderById(orderId)" :printSubPage="true" :printTemplate="printTemplate" :key="orderId"></PrintLabel>
      </template>
    </div>
  </div>
</template>

<script>
import PrintLabel from "./PrintLabel";
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'PrintMultiLabel',
  components: {PrintLabel},
  computed: {
    ...mapGetters({
      printTemplateDefault: 'service/printTemplateDefault',
      orderById: 'order/getById'
    }),
    printTemplate() {
      let templateConfig = this.$router.currentRoute.params.printTemplate;
      if (!templateConfig) return this.printTemplateDefault;
      return JSON.parse(templateConfig)
    }
  },
  methods: {
    ...mapActions({
      getListOrders: 'order/get',
    }),
    async initListOrder() {
      this.listOrderIds = JSON.parse("[" + this.$router.currentRoute.params.orderIds + "]")
      await this.getListOrders({
        searchFields: {
          id: {
            value: this.$router.currentRoute.params.orderIds,
            type: 'IN'
          }
        },
        searchWith: 'lastTrackingNumber;customer',
        searchWithCount: '',
        options: {
          itemsPerPage: -1,
        },
      })
      await new Promise(resolve => setTimeout(resolve, 100));
      this.status = 'done'
    }
  },
  data: () => ({
    listOrderIds: [],
    status: 'init',
  }),
  created() {
    this.initListOrder();
  },
}
</script>
